import React, { memo } from 'react'
import dynamic from 'next/dynamic'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import { urlCredito } from 'vars'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Timeline from '@mui/lab/Timeline'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'components/shared/Image'
import { useModal } from 'components/shared/Modal'
import useReviews from 'hooks/useReviews'
import Text, { TEXT_TYPES } from 'components/designSystem/Text'
import TimelineItemSecundary from '../TimelineItemSecundary'
import sx from './styles'

const RatingCardDynamic = dynamic(() => import('components/shared/RatingCard'))
const CallToActionModalDynamic = dynamic(() => import('components/common/CallToActionModal'))
const TimelineTabsDynamic = dynamic(() => import('components/common/Timeline/TimelineTabs'))

const TimelineItem = ({
  item, sx: sxProp = {}, tabs, showLinks = false,
}) => {
  const router = useRouter()
  const googleReviews = useReviews()
  const { formatMessage: f } = useIntl()
  const { handleOpenModal, handleCloseModal } = useModal()
  const {
    icon,
    titleIcon,
    title,
    subtitle,
    description,
    ctaText,
    ctaButton,
    urlType,
    modalTitle,
    cardModal,
    modalDisclaimer,
    modalType,
    pdfTitle,
    pdfUrl,
    modalContent,
    displayModalTabs,
    items = [],
    showRating = false,
    customClick,
    variantRequirementTitle = 'h4',
    disclaimer,
    descriptionChild = null,
  } = item
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const goToFinancing = () => {
    const element = document.getElementById('vdp-financing').offsetTop
    const top = !isMobile ? element - 200 : element + 820
    setTimeout(() => {
      window.scroll({ top, behavior: 'smooth' })
    }, 500)
  }

  const handleClickModal = (url) => {
    if (url === 'vende-tu-auto') {
      window.open(`/${url}`, '_blank', 'noopener')
    } else if (url === urlCredito) {
      if (router.asPath !== '/autos-seminuevos' || router.asPath !== '/') {
        window.open(`/${urlCredito}`, '_blank', 'noopener')
      } else {
        goToFinancing()
      }
    } else {
      router.push(`/${url}`)
    }
    handleCloseModal()
  }

  const callToActions = {
    modal: () => handleOpenModal({
      title: modalTitle,
      body: displayModalTabs ? (
        <TimelineTabsDynamic
          tabs={tabs}
          handleClickLink={handleClickModal}
        />
      ) : (
        <CallToActionModalDynamic
          data={cardModal}
          content={modalContent}
          href={pdfUrl}
          modalType={modalType}
          pdfTitle={pdfTitle}
          disclaimer={modalDisclaimer}
          variantTitle={variantRequirementTitle}
        />
      ),
      configProps: {
        isFormattedTitle: false,
        showClose: true,
        scroll: 'body',
        isDividerHeader: !displayModalTabs,
        sx: sx.modal,
      },
    }),
    internal_url: () => {
      router.push(`/${ctaButton}`)
      handleCloseModal()
    },
    external_url: () => {
      window.open(ctaButton, '_blank', 'noopener')
      handleCloseModal()
    },
  }

  return (
    <Box sx={sxProp}>
      {icon && (
        <Box sx={{ display: 'flex', '& svg': { fontSize: 26 } }}>
          {typeof icon === 'string' && icon ? (
            <Image
              src={icon}
              width={26}
              height={26}
              alt={titleIcon || title}
              layout="fill"
              objectFit="contain"
              blurDataURL={icon}
            />
          ) : (
            icon || <Box sx={{ width: 26, height: 26 }} />
          )}
          {titleIcon && (
            <Text
              type={TEXT_TYPES.heading.h3}
              sx={sx.title}
            >
              {titleIcon}
            </Text>
          )}
        </Box>
      )}

      <Typography
        variant="body1"
        sx={sx.title}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="h3"
          sx={sx.subtitle}
        >
          {subtitle}
        </Typography>
      )}
      {description && (
        <Text
          type={TEXT_TYPES.body.x1}
          mr={1}
        >
          {description}
        </Text>
      )}
      {descriptionChild}
      {ctaText && showLinks && (
        <Button
          variant="text"
          sx={{ mt: 1 }}
          onClick={customClick || callToActions[urlType]}
        >
          <Typography
            variant="link-text"
            sx={sx.actionLabel}
          >
            {ctaText}
          </Typography>
        </Button>
      )}

      {items && items.length > 0 && (
        <Timeline sx={sx.timelineRoot}>
          {items.map((itemSecundary, index) => (
            <TimelineItemSecundary
              item={itemSecundary}
              key={`secundary-item-${index}`}
              onClickModal={handleClickModal}
            />
          ))}
        </Timeline>
      )}
      {disclaimer && (
        <Box mt={2}>
          <Typography
            variant="body2"
            sx={sx.disclaimer}
          >
            {disclaimer}
          </Typography>
        </Box>
      )}
      {showRating && (
        <Box sx={sx.ratingContainer}>
          <RatingCardDynamic
            rating={googleReviews?.rating ?? 0}
            text={f({ id: 'SYC_BEST_OPTION_TO_SELL_A_CAR' })}
            variant="column"
            textVariant={TEXT_TYPES.strong}
          />
        </Box>
      )}
    </Box>
  )
}
export default memo(TimelineItem)
