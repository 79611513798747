import React, { memo } from 'react'
import Typography from '@mui/material/Typography'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Box from '@mui/material/Box'
import Image from 'components/shared/Image'
import sx from './styles'

const TimelineItemSecundary = ({ item, onClickModal }) => {
  const iconRender = (icon, iconTitle) => {
    return (
      typeof icon === 'string' ? (
        <Image
          src={icon}
          width={26}
          height={26}
          alt={iconTitle}
        />
      ) : (
        icon || <Box sx={{ width: 26, height: 26 }} />
      )
    )
  }

  return (
    <TimelineItem
      sx={sx.timelineItem}
    >
      <TimelineSeparator sx={sx.timelineSeparatorSecundary}>
        <TimelineDot
          sx={sx.timelineDot}
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box
          ml={1}
          mb={2}
        >
          <Box sx={sx.titleIcon}>
            {iconRender(item.icon, item.title)}
            {item.icon_text && (
              <Typography variant="body2">
                {item.icon_text}
              </Typography>
            )}
          </Box>
          <Typography
            variant="h3"
            mt={1}
          >
            {item.title}
          </Typography>
          {item.description && (
            <Typography
              variant="body1"
              mt={1}
            >
              {item.description}
            </Typography>
          )}
          {item.cta_text_button && (
            <Typography
              variant="link-text"
              onClick={() => onClickModal(item.cta_button)}
            >
              {item.cta_text_button}
            </Typography>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  )
}

export default memo(TimelineItemSecundary)
